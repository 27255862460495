// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actie-js": () => import("./../../../src/pages/actie.js" /* webpackChunkName: "component---src-pages-actie-js" */),
  "component---src-pages-collectie-js": () => import("./../../../src/pages/collectie.js" /* webpackChunkName: "component---src-pages-collectie-js" */),
  "component---src-pages-components-banner-js": () => import("./../../../src/pages/components/Banner.js" /* webpackChunkName: "component---src-pages-components-banner-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/Footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-game-js": () => import("./../../../src/pages/components/Game.js" /* webpackChunkName: "component---src-pages-components-game-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/Header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-components-list-filter-js": () => import("./../../../src/pages/components/ListFilter.js" /* webpackChunkName: "component---src-pages-components-list-filter-js" */),
  "component---src-pages-components-price-check-js": () => import("./../../../src/pages/components/PriceCheck.js" /* webpackChunkName: "component---src-pages-components-price-check-js" */),
  "component---src-pages-components-price-confirmation-js": () => import("./../../../src/pages/components/PriceConfirmation.js" /* webpackChunkName: "component---src-pages-components-price-confirmation-js" */),
  "component---src-pages-components-prices-js": () => import("./../../../src/pages/components/Prices.js" /* webpackChunkName: "component---src-pages-components-prices-js" */),
  "component---src-pages-components-subscribe-newsletter-form-js": () => import("./../../../src/pages/components/SubscribeNewsletterForm.js" /* webpackChunkName: "component---src-pages-components-subscribe-newsletter-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

